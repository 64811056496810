import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
Vue.use(Vant)

//配置全局axios
import axios from 'axios'
Vue.prototype.$axios = axios
const url = 'https://api.qk.zhongheinfo.com';
axios.interceptors.request.use(
  config => {
    let token = localStorage.getItem("x-auth-token") || router.query.token;
    let site = localStorage.getItem("x-auth-site") || router.query.site;
    if (token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.token = `${token}`;
    }
    if (site) {
      config.headers.site = `${site}`;
    }
    if (config.url.indexOf('aliyuncs.com') === -1) {
      config.url = url + config.url;/*拼接完整请求路径*/
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  });

axios.interceptors.response.use(response => {
  if (response.data.code == '602') {
    //用户token过期
    localStorage.clear()
    // router.push({
    //   name: 'login'
    // })
    return false
  }
  return Promise.resolve(response);
},
  err => {
    return Promise.reject(err);
  })

Vue.config.productionTip = false

Vue.directive('title', {
  inserted: function (el) {
    document.title = el.dataset.title
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
