<template>
  <router-view />
</template>
<script>
import { routerBut } from '@/unit/unit.js'
export default {
  beforeMount() {
    if (this.$route.query.token) {
      localStorage.setItem('x-auth-token', this.$route.query.token)
    }
    if (this.$route.query.site) {
      localStorage.setItem('x-auth-site', this.$route.query.site)
    }else{
      localStorage.setItem('x-auth-site', 'default')
    }
  },
  mounted() {
    //初始化相关配置
    //登录后，每次获取用户信息保存在vuex里
    this.$axios.post('/user/detail').then((res) => {
      this.$store.commit('set_user', res.data.data)
    })

    //缓存所有用户列表
    this.$axios.post('/index/users').then((res) => {
      this.$store.commit('set_userList', res.data.data.list)
    })

    //缓存基础数据
    this.$axios.post('/cfgdata/lists').then((res) => {
      var json = {}
      res.data.data.map((item) => {
        json[item.key] = item.list
      })
      this.$store.commit('set_cfgList', json)
    })

    this.$axios.post('/index/cfgdata').then((res) => {
      this.$store.commit('set_cfgData', res.data.data.list)
    })

    this.$axios.post('/index/group').then((res) => {
      let obj = res.data.data.list
      let jsonData = []

      for (var p in obj) {
        jsonData.push({
          label: obj[p],
          value: p,
        })
      }
      this.$store.commit('set_role', jsonData)
    })

    //缓存菜单
    this.$axios.post('/user/menu').then((res) => {
      this.$store.commit('set_menu', res.data.data)
      this.$store.commit('set_auth', routerBut(res.data.data))
    })
  },
}
</script>
<style lang="less" scoped>
.layout {
  background: #f7f8fa;
}
.item-title {
  margin: 0;
  padding: 16px 16px 16px;
  color: rgba(69, 90, 100, 0.6);
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}
</style>