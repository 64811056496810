import store from '../store/index.js'
export function sellList() {
	let obj = store.state.userList
	let arr = []
	Object.keys(obj).map(key => {
		//如果用户有效且是销售顾问
		if (obj[key][1] == 2 && obj[key][2] == 1) {
			arr.push({
				text: obj[key][0],
				value: key
			})
		}
	})
	return arr
}

export function cfgList(type) {
	let arr = []
	store.state.cfgList[type].map((item) => {
		if (item.status == 1) {
			arr.push({
				text: item.f1,
				value: item.sys_id
			})
		}
	})
	return arr
}

export function fileToBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});
}

export function dataURLtoFile(dataurl, filename) {
	var arr = dataurl.split(","),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], filename, { type: mime });
}

export function compare(property) {
	return function (obj1, obj2) {
		var value1 = obj1[property];
		var value2 = obj2[property];
		return value1 - value2;     // 升序
	}
}

//根据ID返回职务信息
export function roleByID(id) {
	if (id == '10000') {
		return {
			label: '-',
			value: '10000'
		}
	}
	return store.state.role.find((value) => {
		if (value.value == id) {
			return value
		}
	})
}

export function monthFormat2() {
	// 获取当前日期
	var date = new Date()

	// 获取当前月份
	var nowMonth = date.getMonth() + 1

	// 对月份进行处理，1-9月在前面添加一个“0”
	if (nowMonth >= 1 && nowMonth <= 9) {
		nowMonth = '0' + nowMonth
	}

	// 最后拼接字符串，得到一个格式为(yyyyMM)的日期
	return date.getFullYear() + nowMonth

}

export function monthFormat() {
	// 获取当前日期
	var date = new Date()

	// 获取当前月份
	var nowMonth = date.getMonth() + 1

	// 对月份进行处理，1-9月在前面添加一个“0”
	if (nowMonth >= 1 && nowMonth <= 9) {
		nowMonth = '0' + nowMonth
	}

	// 最后拼接字符串，得到一个格式为(yyyy-MM-dd)的日期
	return date.getFullYear() + '-' + nowMonth

}

export function dateFormat() {
	// 获取当前日期
	var date = new Date()

	// 获取当前月份
	var nowMonth = date.getMonth() + 1

	// 获取当前是几号
	var strDate = date.getDate()

	// 添加分隔符“-”
	var seperator = '-'

	// 对月份进行处理，1-9月在前面添加一个“0”
	if (nowMonth >= 1 && nowMonth <= 9) {
		nowMonth = '0' + nowMonth
	}

	// 对月份进行处理，1-9号在前面添加一个“0”
	if (strDate >= 0 && strDate <= 9) {
		strDate = '0' + strDate
	}

	// 最后拼接字符串，得到一个格式为(yyyy-MM-dd)的日期
	return date.getFullYear() + seperator + nowMonth + seperator + strDate
}

export function getTimeInterval(time) {
	if (time <= '09:00') {
		return 74
	}

	if (time <= '10:00' && time > '09:00') {
		return 75
	}

	if (time <= '11:00' && time > '10:00') {
		return 77
	}

	if (time <= '12:00' && time > '11:00') {
		return 78
	}

	if (time <= '13:00' && time > '12:00') {
		return 79
	}

	if (time <= '14:00' && time > '13:00') {
		return 80
	}

	if (time <= '15:00' && time > '14:00') {
		return 81
	}

	if (time <= '16:00' && time > '15:00') {
		return 169
	}

	if (time <= '17:00' && time > '16:00') {
		return 170
	}

	if (time > '17:00') {
		return 171
	}

}

export function routerBut(routerData) {
	var arr = {}
	// console.log(routerData)
	routerData.forEach(function (v, i) {
		if (v.type === 1) {
			arr[v.route] = {}
		} else {
			arr[v.route] = true
		}

		if (v.children) {
			arr[v.route] = routerBut(v.children)
		}
	})
	return arr
}