import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/Layout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/chart',
    name: 'chart',
    component: Layout,
    children: [
      {
        path: '/chart/info',
        name: 'chart-info',
        component: () => import('../views/chart/Info.vue')
      },
      {
        path: '/chart/sell',
        name: 'chart-sell',
        component: () => import('../views/chart/Sell.vue')
      },
      {
        path: '/chart/market',
        name: 'chart-market',
        component: () => import('../views/chart/Market.vue')
      },
      {
        path: '/chart/cmarket',
        name: 'chart-cmarket',
        component: () => import('../views/chart/Cmarket.vue')
      },
      {
        path: '/chart/sellone',
        name: 'chart-sellone',
        component: () => import('../views/chart/Sellone.vue')
      },
      {
        path: '/chart/selltwo',
        name: 'chart-selltwo',
        component: () => import('../views/chart/Selltwo.vue')
      },
      {
        path: '/chart/fall',
        name: 'chart-fall',
        component: () => import('../views/chart/Fall.vue')
      },
      {
        path: '/customer/trace',
        name: 'customer-trace',
        component: () => import('../views/customer/trace.vue')
      },
      {
        path: '/customer/data',
        name: 'customer-data',
        component: () => import('../views/customer/data.vue')
      },
      {
        path: '/customer/datadetail',
        name: 'customer-datadetail',
        component: () => import('../views/customer/datadetail.vue')
      },
      {
        path: '/customer/tracking',
        name: 'customer-tracking',
        component: () => import('../views/customer/Tracking.vue')
      },
      {
        path: '/customer/record',
        name: 'customer-record',
        component: () => import('../views/customer/Record.vue')
      },
      {
        path: '/customer/tracedetail',
        name: 'customer-tracedetail',
        component: () => import('../views/customer/Tracedetail.vue')
      },
      {
        path: '/customer/register',
        name: 'customer-register',
        component: () => import('../views/customer/Register.vue')
      },
      {
        path: '/company/task',
        name: 'company-task',
        component: () => import('../views/company/Task.vue')
      },
      {
        path: '/customer/confirm',
        name: 'customer-confirm',
        component: () => import('../views/customer/Confirm.vue')
      }
    ]
  },
  {
    path: '/dev',
    name: 'dev',
    component: () => import('../views/dev.vue')
  }


]

const router = new VueRouter({
  routes
})

export default router
